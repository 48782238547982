import React from "react";
import { FaArrowRight } from "react-icons/fa";

const CoSpaceDetails = () => {
  return (
    <>
      {/* ==================== Co-Space Details Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_info">
                  <h5 className="widget-title">
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className="widget-info-inner">
                    <h6>Clients</h6>
                    <p>Co-Space Park</p>
                    <h6>Date</h6>
                    <p>2018</p>
                    <h6>Location</h6>
                    <p>43 Science Park Rd, #01-11, Singapore 117408</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="thumb">
                    <img src="assets/img/Projects/Co-Space.png" alt="img" />
                  </div>
                  <div className="details">
                    <h4>What they want?</h4>
                    <p>
                      Co-Space Park tenanted multiple types company of
                      different-sized-office units sharing a common resources
                      such as meeting rooms, photocopying machines, phonebooth,
                      appliances etc. User wanted both door access and light
                      control to be controllable via mobile app with multi-tier
                      admin log-in and access rights to better managed and
                      control usage of the shared resources and facilities.
                    </p>
                    <h4>Our Solution</h4>
                    <p>
                      29 nos. of C2ME100 door controller, 2 nos. of C2ME200
                      gateway and 35 nos. of BWS4EU switch are deployed to
                      enable lighting of all meeting rooms can be controllable
                      by both wall switch and mobile app. Administrator can use
                      the app to check status of room booking.
                    </p>
                    <h4>The Results</h4>
                    <p>
                      Administrator can schedule all lights to be switch "OFF"
                      for all meeting rooms with a single click without having
                      to go to every room. This has greatly improved the
                      efficiency and provide savings on electricity bills.
                      Tenants can be assigned with access right to check room
                      availability and control using both mobile app and wall
                      panel without messy and costly cabling works.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Co-Space Details End ====================*/}
    </>
  );
};

export default CoSpaceDetails;
