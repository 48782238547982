import React from "react";
import { FaArrowRight } from "react-icons/fa";

const AgricultureDetails = () => {
  return (
    <>
      {/* ==================== Agriculture Details Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_info">
                  <h5 className="widget-title">
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className="widget-info-inner">
                    <h6>Type</h6>
                    <p>Agriculture</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="thumb">
                    <img src="assets/img/Projects/Agriculture.png" alt="img" />
                  </div>
                  <br />
                  <h4>Agriculture</h4>
                  <p>
                    Based on agricultural IoT scenarios, C2ME is creating smart
                    agriculture solutions, providing technical security for
                    China's agricultural data, and further promoting the
                    digitization of agriculture. The C2ME smart agriculture
                    solution integrates emerging Internet, mobile Internet,
                    cloud computing, and IoT technologies. It relies on various
                    sensor nodes deployed in agricultural production sites
                    (environmental temperature and humidity, soil moisture,
                    carbon dioxide, images, etc.) and wireless communication
                    networks to achieve intelligent perception, intelligent
                    warning, intelligent decision-making, intelligent analysis,
                    and expert online guidance for agricultural production
                    environments. It provides precision planting, visual
                    management, and intelligent decision-making for agricultural
                    production.
                  </p>
                  <br />
                  <div className="details">
                    <video controls width="640" height="360">
                      <source
                        src="assets/img/Projects/Agriculture.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Agriculture Details End ====================*/}
    </>
  );
};

export default AgricultureDetails;
