import React from "react";
import { FaArrowRight } from "react-icons/fa";

const DiscoveryDetails = () => {
  return (
    <>
      {/* ====================  SG Discovery Details Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_info">
                  <h5 className="widget-title">
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className="widget-info-inner">
                    <h6>Clients</h6>
                    <p>Singapore Discovery Centre (SDC)</p>
                    <h6>Date</h6>
                    <p>2021</p>
                    <h6>Location</h6>
                    <p>510 Upper Jurong Rd, Singapore 638365</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="thumb">
                    <img
                      src="assets/img/Projects/DiscoveryCenter.png"
                      alt="img"
                    />
                  </div>
                  <div className="details">
                    <h4>What they want?</h4>
                    <p>
                      SDC needs colour of 60 lights to be changed monthly to
                      create different scenes for multiple events. In normal
                      system, every light is to be paired individually to a
                      remote control. Hence, SDC will need 60 remote controllers
                      to control and change the colours of 60 lights. There will
                      be too many settings for the desire combinations. The
                      deployment is not a light show. Due to budget constraint,
                      implementing conventional wired solution with coloured
                      bulb is not viable.
                    </p>
                    <h4>Our Solution</h4>
                    <p>
                      1. C2Me RGB Cube which is IP65 rated is used. It can
                      withstand water is suitable for outdoor use.
                      <br /> 2. When used with C2Me lighting control platform,
                      all Cubes can be individually controlled or grouped to
                      create multiple lighting scenes.
                      <br /> 3. C2ME Gateway and Dashboard enable remote
                      programming and control.
                    </p>
                    <h4>The Results</h4>
                    <p>
                      Within limited budget, SDC is able program and control
                      limitless colour scenes and change the ON/OFF schedule of
                      every Cube remotely.
                    </p>
                    <br />
                    <video controls width="640" height="360">
                      <source
                        src="assets/img/Projects/DiscoveryCenter.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================  SG Discovery Details End ====================*/}
    </>
  );
};

export default DiscoveryDetails;
