import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import MessageMain from "../components/MessageMain";

const Contact = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Message Main */}
      <MessageMain />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Contact;
