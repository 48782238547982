import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import AgricultureDetails from "../components/AgricultureDetails";

const SGDiscoveryCenter = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Agriculture Details Area */}
      <AgricultureDetails />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default SGDiscoveryCenter;
