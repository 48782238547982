import React, { useState } from "react";
import { Link } from "react-router-dom";
const { useTranslation } = require("react-i18next");

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const lngs = {
    en: { nativeName: "English", flag: "assets/img/flag_usa.png" },
    zh: { nativeName: "中文", flag: "assets/img/flag_china.png" },
  };
  let flags = lngs[i18n.language.split("-")[0]].flag;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    flags = lngs[lng].flag;
  };

  const [active, setActive] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <>
      {/* ==================== Navbar Two Start ====================*/}
      <nav className="navbar navbar-area navbar-area-2 navbar-expand-lg">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <Link to="/">
              <img src="assets/img/C2MeLogo.png" alt="img" />
            </Link>
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              <li>
                <Link to="/index">{t("NavBar.home")}</Link>
              </li>
              <li className="menu-item-has-children">
                <a>{t("NavBar.aboutUs")}</a>
                <ul className="sub-menu">
                  <li>
                    <Link to="/about">{t("NavBar.intro")}</Link>
                  </li>
                  <li>
                    <Link to="/RnD">{t("NavBar.RnD")}</Link>
                  </li>
                  <li>
                    <Link to="/contact">{t("NavBar.contactUs")}</Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a>{t("NavBar.whatWeOffer")}</a>
                <ul className="sub-menu">
                  <li>
                    <Link to="/service">{t("NavBar.services")}</Link>
                  </li>
                  <li>
                    <Link to="/products">{t("NavBar.products")}</Link>
                  </li>
                  <li>
                    <Link to="/projects">{t("NavBar.projects")}</Link>
                  </li>
                  {/* <li>
                    <Link to="/Demo">{t("NavBar.demo")}</Link>
                  </li> */}
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a>{t("NavBar.ourMessage")}</a>
                <ul className="sub-menu">
                  <li>
                    <Link to="/message">{t("NavBar.latestNews")}</Link>
                  </li>
                  <li>
                    <Link to="/tutorial">{t("NavBar.tutorial")}</Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a>
                  <img src={flags} alt="img" width={"40px"} />
                </a>
                <ul className="sub-menu">
                  {Object.keys(lngs).map((lng) => (
                    <li
                      type="submit"
                      key={lng}
                      onClick={() => changeLanguage(lng)}
                      disabled={i18n.resolvedLanguage === lng}
                    >
                      <Link>{lngs[lng].nativeName}</Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="nav-right-part nav-right-part-desktop align-self-center">
                <Link
                  to="https://apps.c2me.net/enterprise/"
                  target="_blank"
                  style={{
                    padding: "12px 20px", // Adjust padding for better appearance
                    backgroundColor: "#00FFF6",
                    display: "inline-flex", // Use flex box for centering
                    alignItems: "center", // Center vertically
                    justifyContent: "center", // Center horizontally
                    height: "40px", // Adjust height as needed
                    borderRadius: "10px", // Adjust border radius for rounder corners
                    // border: "2px solid blue",
                  }}
                >
                  {t("NavBar.login")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavBar;
