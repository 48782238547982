import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProjectsMain = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };
  const { t } = useTranslation();

  return (
    <>
      {/* Projects Main Start */}
      <div className="service-area bg-relative pd-bottom-90">
        <div className="container">
          <div className="section-title text-center ">
            <h2 className="title">
              <br />
              {t("projects.facility")}
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="assets/img/Projects/Church.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link>Living Sanctuary Brethren Church</Link>
                  </h5>
                  <Link
                    to="/church"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="assets/img/Projects/Co-Space.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link>Co-Space Park</Link>
                  </h5>
                  <Link
                    to="/co-space"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="assets/img/Projects/AILab.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link>AI Lab, Singapore</Link>
                  </h5>
                  <Link
                    to="/ai-lab"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="assets/img/Projects/Mendaki.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link>Mendaki, Singapore</Link>
                  </h5>
                  <Link
                    to="/mendaki"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="assets/img/Projects/Gympod.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link>Gympod</Link>
                  </h5>
                  <Link
                    to="/gympod"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="assets/img/Projects/Malaysia.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link>Ministry of Works, Malaysia</Link>
                  </h5>
                  <Link
                    to="/mow"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/Projects/Huawei.png"
                    alt="img"
                    style={{ height: "230px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>Huawei</Link>
                  </h5>
                  <Link
                    to="/huawei"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="section-title text-center ">
            <h2 className="title">
              <br />
              {t("projects.events")}
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{ height: "450px" }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Projects/DiscoveryCenter.png"
                    alt="img"
                    style={{ height: "230px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>Singapore Discovery Center</Link>
                  </h5>
                  <Link
                    to="/singapore-discovery-center"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{ height: "450px" }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Projects/Sentosa.png"
                    alt="img"
                    style={{ height: "230px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>Sentosa</Link>
                  </h5>
                  <Link
                    to="/sentosa"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{ height: "450px" }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Projects/GBTB.png"
                    alt="img"
                    style={{ height: "230px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>Gardens By The Bay</Link>
                  </h5>
                  <Link
                    to="/gbtb"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="section-title text-center ">
            <h2 className="title">
              <br />
              {t("projects.agriculture")}
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{ height: "450px" }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Projects/Agriculture.png"
                    alt="img"
                    style={{ height: "230px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>Agriculture</Link>
                  </h5>
                  <Link
                    to="/agriculture"
                    className="btn btn-border-base"
                    onClick={scrollToTop}
                  >
                    Learn More <FaPlus />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================= Projects Main End =================*/}
    </>
  );
};

export default ProjectsMain;
