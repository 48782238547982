import React, { useState } from "react";
import { ChromePicker } from "react-color";
import Popup from "./Popup";
import PopupColor from "./PopupColor";

const DemoMain = () => {
  // Handle Demo Containers
  const [showDemoContainers, setShowDemoContainers] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);

  const handleImageClick = (index) => {
    const updatedContainers = showDemoContainers.map((_, i) => i === index);
    setShowDemoContainers(updatedContainers);
  };

  // Handle Color Picker
  const [colors, setColors] = useState({
    color1: "#ff0000",
    color2: "#ffffff",
    color3: "#ffffff",
    color4: "#ffffff",
    color5: "#ff0000",
    color6: "#ffffff",
    color7: "#ffffff",
    color8: "#ffffff",
    color9: "#ff0000",
    color10: "#ff0000",
    color11: "#ff0000",
    color12: "#ffffff",
    color13: "#ff0000",
  });
  const handleChangeComplete = (color, colorKey) => {
    setColors({ ...colors, [colorKey]: color.hex });
  };

  // Handle Popups
  const [showPopups, setShowPopups] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const togglePopup = (index) => {
    setShowPopups((prevShowPopups) => {
      return prevShowPopups.map((value, i) => (i === index ? !value : false));
    });
  };

  //Handle Sliders
  const [sliderValues, setSliderValues] = useState({
    value1: 50,
    value2: 50,
    value3: 50,
    value4: 50,
    value5: 50,
    value6: 50,
    value7: 50,
    value8: 50,
    value9: 50,
  });
  const handleSliderChanges = (event, slider) => {
    const value = parseInt(event.target.value, 10);
    setSliderValues((prevValues) => ({
      ...prevValues,
      [slider]: value,
    }));
  };
  const trackStyles = (slider) => ({
    background: `linear-gradient(to right, #04AA6D 0%, #04AA6D ${sliderValues[slider]}%, #d3d3d3 ${sliderValues[slider]}%, #d3d3d3 100%)`,
  });

  return (
    <>
      {/* ====================  Demo Main Start ====================*/}
      <div className="container">
        <div className="row">
          <div className="column text-center">
            <h2>Demo</h2>
            <div>
              <a>
                Please note that this video is a bit delayed, expect to wait for
                around less than 20 seconds.
              </a>
            </div>
            <div>
              <iframe
                width="640"
                height="480"
                src="https://rtsp.me/embed/ry3GzkaR/"
                frameborder="0"
                allowfullscreen
              ></iframe>
              <p align="right">
                powered by{" "}
                <a
                  href="https://rtsp.me"
                  title="RTSP.ME - Free website RTSP video steaming service"
                  target="_blank"
                >
                  rtsp.me
                </a>
              </p>
            </div>
            <div>
              <div className="gallery-images">
                {/* Images */}
                <img
                  src="/assets/img/demo/BLEControllerIcon.png"
                  alt="BLE"
                  onClick={() => handleImageClick(0)}
                  className="thumbnail"
                />
                <img
                  src="/assets/img/demo/RGBW-Icon.png"
                  alt="RGBW"
                  onClick={() => handleImageClick(1)}
                  className="thumbnail"
                />
                <img
                  src="/assets/img/demo/Dimming-Icon.png"
                  alt="Dimmable"
                  onClick={() => handleImageClick(2)}
                  className="thumbnail"
                />
                <img
                  src="/assets/img/demo/Tunable-White-Icon.png"
                  alt="Tunable White"
                  onClick={() => handleImageClick(3)}
                  className="thumbnail"
                />
                <img
                  src="/assets/img/demo/Lights-Sensors-Icon.png"
                  alt="RGBW"
                  onClick={() => handleImageClick(4)}
                  className="thumbnail"
                />
              </div>

              {/* Demo Container 1 */}
              {showDemoContainers[0] && (
                <div className="demo-container">
                  <img src="/assets/img/demo/BLEController.png" alt="BLE" />
                  <div className="close-container">
                    <button
                      onClick={() =>
                        setShowDemoContainers([
                          false,
                          false,
                          false,
                          false,
                          false,
                        ])
                      }
                      className="close-btn"
                    >
                      <img
                        src="/assets/img/demo/cross-button.png"
                        alt="close"
                        className="close-img"
                      />
                    </button>
                  </div>

                  {/* Button 1 */}
                  <button
                    onClick={() => togglePopup(0)}
                    className="icon-btn demo1btn1"
                  >
                    <img src="/assets/img/demo/brightness.png" alt="dimmable" />
                    <Popup show={showPopups[0]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(0)}
                      />
                      <h5>RGB</h5>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={sliderValues.value1}
                        className="slider"
                        id="myRange"
                        onChange={(event) =>
                          handleSliderChanges(event, "value1")
                        }
                        style={trackStyles("value1")}
                      />
                      <p>Brightness: {sliderValues.value1}</p>
                    </Popup>
                  </button>

                  {/* Button 2 */}
                  <button
                    onClick={() => togglePopup(1)}
                    className="icon-btn demo1btn2"
                  >
                    <img src="/assets/img/demo/brightness.png" alt="dimmable" />
                    <Popup show={showPopups[1]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(1)}
                      />
                      <h5>RGB</h5>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={sliderValues.value2}
                        className="slider"
                        id="myRange"
                        onChange={(event) =>
                          handleSliderChanges(event, "value2")
                        }
                        style={trackStyles("value2")}
                      />
                      <p>Brightness: {sliderValues.value2}</p>
                    </Popup>
                  </button>

                  {/* Button 3  */}
                  <button
                    onClick={() => togglePopup(2)}
                    className="icon-btn demo1btn3"
                  >
                    <img
                      src="/assets/img/demo/on-off-button.png"
                      alt="on/off"
                    />
                    <Popup show={showPopups[2]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(2)}
                      />
                      <h5>On / Off</h5>
                      <div className="toggle-switch">
                        <input
                          type="checkbox"
                          id="toggle"
                          className="toggle-input"
                        />
                        <label
                          htmlFor="toggle"
                          className="toggle-label"
                        ></label>
                      </div>
                    </Popup>
                  </button>
                </div>
              )}

              {/* Demo Container 2 */}
              {showDemoContainers[1] && (
                <div className="demo-container">
                  <img
                    src="/assets/img/demo/RGBW-BLE-Controller.png"
                    alt="RGBW"
                  />
                  <div className="close-container">
                    <button
                      onClick={() =>
                        setShowDemoContainers([
                          false,
                          false,
                          false,
                          false,
                          false,
                        ])
                      }
                      className="close-btn"
                    >
                      <img
                        src="/assets/img/demo/cross-button.png"
                        alt="close"
                        className="close-img"
                      />
                    </button>
                  </div>
                  {/* Button 1 */}
                  <button
                    onClick={() => togglePopup(3)}
                    className="icon-btn demo1btn1"
                  >
                    <img src="/assets/img/demo/rgb.png" alt="rgb" />
                    <Popup show={showPopups[3]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(3)}
                      />
                      <h5>RGB</h5>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={sliderValues.value3}
                        className="slider"
                        id="myRange"
                        onChange={(event) =>
                          handleSliderChanges(event, "value3")
                        }
                        style={trackStyles("value3")}
                      />
                      <p>White: {sliderValues.value3}</p>
                      <div>
                        <p>
                          Select your favorite color:
                          <input
                            type="color"
                            id="favcolor"
                            defaultValue="#ff0000"
                          />
                        </p>
                      </div>
                    </Popup>
                  </button>
                  {/* Button 2 */}
                  <button
                    onClick={() => togglePopup(4)}
                    className="icon-btn demo2btn2"
                    style={{ backgroundColor: colors.color1 }}
                  >
                    <PopupColor show={showPopups[4]}>
                      <div>
                        <ChromePicker
                          color={colors.color1}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color1")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                  {/* Button 3 */}
                  <button
                    onClick={() => togglePopup(5)}
                    className="icon-btn demo2btn3"
                    style={{ backgroundColor: colors.color2 }}
                  >
                    <PopupColor show={showPopups[5]}>
                      <div>
                        <ChromePicker
                          color={colors.color2}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color2")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                  {/* Button 4 */}
                  <button
                    onClick={() => togglePopup(6)}
                    className="icon-btn demo2btn4"
                    style={{ backgroundColor: colors.color3 }}
                  >
                    <PopupColor show={showPopups[6]}>
                      <div>
                        <ChromePicker
                          color={colors.color3}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color3")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                  {/* Button 5 */}
                  <button
                    onClick={() => togglePopup(7)}
                    className="icon-btn demo2btn5"
                    style={{ backgroundColor: colors.color4 }}
                  >
                    <PopupColor show={showPopups[7]}>
                      <div>
                        <ChromePicker
                          color={colors.color4}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color4")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                  {/* Button 6 */}
                  <button
                    onClick={() => togglePopup(8)}
                    className="icon-btn demo2btn6"
                    style={{ backgroundColor: colors.color5 }}
                  >
                    <PopupColor show={showPopups[8]}>
                      <div>
                        <ChromePicker
                          color={colors.color5}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color5")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                  {/* Button 7 */}
                  <button
                    onClick={() => togglePopup(9)}
                    className="icon-btn demo2btn7"
                    style={{ backgroundColor: colors.color6 }}
                  >
                    <PopupColor show={showPopups[9]}>
                      <div>
                        <ChromePicker
                          color={colors.color6}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color6")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                  {/* Button 8 */}
                  <button
                    onClick={() => togglePopup(10)}
                    className="icon-btn demo2btn8"
                    style={{ backgroundColor: colors.color7 }}
                  >
                    <PopupColor show={showPopups[10]}>
                      <div>
                        <ChromePicker
                          color={colors.color7}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color7")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                  {/* Button 9 */}
                  <button
                    onClick={() => togglePopup(11)}
                    className="icon-btn demo2btn9"
                    style={{ backgroundColor: colors.color8 }}
                  >
                    <PopupColor show={showPopups[11]}>
                      <div>
                        <ChromePicker
                          color={colors.color8}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color8")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                  {/* Button 10 */}
                  <button
                    onClick={() => togglePopup(12)}
                    className="icon-btn demo2btn10"
                    style={{ backgroundColor: colors.color9 }}
                  >
                    <PopupColor show={showPopups[12]}>
                      <div>
                        <ChromePicker
                          color={colors.color9}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color9")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                </div>
              )}

              {/* Demo Container 3 */}
              {showDemoContainers[2] && (
                <div className="demo-container">
                  <img
                    src="/assets/img/demo/Dimming-BLE-Controller.png"
                    alt="Dimmable"
                  />
                  <div className="close-container">
                    <button
                      onClick={() =>
                        setShowDemoContainers([
                          false,
                          false,
                          false,
                          false,
                          false,
                        ])
                      }
                      className="close-btn"
                    >
                      <img
                        src="/assets/img/demo/cross-button.png"
                        alt="close"
                        className="close-img"
                      />
                    </button>
                  </div>
                  {/* Button 1 */}
                  <button
                    onClick={() => togglePopup(13)}
                    className="icon-btn demo3btn1"
                    style={{ backgroundColor: colors.color10 }}
                  >
                    <PopupColor show={showPopups[13]}>
                      <div>
                        <ChromePicker
                          color={colors.color10}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color10")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                  {/* Button 2 */}
                  <button
                    onClick={() => togglePopup(14)}
                    className="icon-btn demo1btn3"
                  >
                    <img
                      src="/assets/img/demo/on-off-button.png"
                      alt="on/off"
                    />
                    <Popup show={showPopups[14]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(14)}
                      />
                      <h5>RGB</h5>
                      <div>
                        <p>
                          Select your favorite color:
                          <input
                            type="color"
                            id="favcolor"
                            defaultValue="#ff0000"
                          />
                        </p>
                      </div>
                    </Popup>
                  </button>
                </div>
              )}

              {/* Demo Container 4 */}
              {showDemoContainers[3] && (
                <div className="demo-container">
                  <img
                    src="/assets/img/demo/Tunable-White.png"
                    alt="Tunable White"
                  />
                  <div className="close-container">
                    <button
                      onClick={() =>
                        setShowDemoContainers([
                          false,
                          false,
                          false,
                          false,
                          false,
                        ])
                      }
                      className="close-btn"
                    >
                      <img
                        src="/assets/img/demo/cross-button.png"
                        alt="close"
                        className="close-img"
                      />
                    </button>
                  </div>
                  {/* Button 1 */}
                  <button
                    onClick={() => togglePopup(15)}
                    className="icon-btn demo4btn1"
                  >
                    <img src="/assets/img/demo/lightbulb.png" alt="dimmable" />
                    <Popup show={showPopups[15]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(15)}
                      />
                      <h5>Dimmable</h5>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={sliderValues.value4}
                        className="slider"
                        id="myRange"
                        onChange={(event) =>
                          handleSliderChanges(event, "value4")
                        }
                        style={trackStyles("value4")}
                      />
                      <p>Brightness: {sliderValues.value4}%</p>
                    </Popup>
                  </button>
                  {/* Button 2 */}
                  <button
                    onClick={() => togglePopup(16)}
                    className="icon-btn demo2btn3"
                  >
                    <img src="/assets/img/demo/lightbulb.png" alt="dimmable" />
                    <Popup show={showPopups[16]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(16)}
                      />
                      <h5>Dimmable</h5>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={sliderValues.value5}
                        className="slider"
                        id="myRange"
                        onChange={(event) =>
                          handleSliderChanges(event, "value5")
                        }
                        style={trackStyles("value5")}
                      />
                      <p>Brightness: {sliderValues.value5}%</p>
                    </Popup>
                  </button>
                  {/* Button 3 */}
                  <button
                    onClick={() => togglePopup(17)}
                    className="icon-btn demo2btn6"
                  >
                    <img src="/assets/img/demo/lightbulb.png" alt="dimmable" />
                    <Popup show={showPopups[17]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(17)}
                      />
                      <h5>Dimmable</h5>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={sliderValues.value6}
                        className="slider"
                        id="myRange"
                        onChange={(event) =>
                          handleSliderChanges(event, "value6")
                        }
                        style={trackStyles("value6")}
                      />
                      <p>Brightness: {sliderValues.value6}%</p>
                    </Popup>
                  </button>
                  {/* Button 4 */}
                  <button
                    onClick={() => togglePopup(18)}
                    className="icon-btn demo2btn9"
                  >
                    <img src="/assets/img/demo/lightbulb.png" alt="dimmable" />
                    <Popup show={showPopups[18]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(18)}
                      />
                      <h5>Dimmable</h5>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={sliderValues.value7}
                        className="slider"
                        id="myRange"
                        onChange={(event) =>
                          handleSliderChanges(event, "value7")
                        }
                        style={trackStyles("value7")}
                      />
                      <p>Brightness: {sliderValues.value7}%</p>
                    </Popup>
                  </button>
                </div>
              )}

              {/* Demo Container 5 */}
              {showDemoContainers[4] && (
                <div className="demo-container">
                  <img
                    src="/assets/img/demo/Lights-Sensors.png"
                    alt="light-sensors"
                  />
                  <div className="close-container">
                    <button
                      onClick={() =>
                        setShowDemoContainers([
                          false,
                          false,
                          false,
                          false,
                          false,
                        ])
                      }
                      className="close-btn"
                    >
                      <img
                        src="/assets/img/demo/cross-button.png"
                        alt="close"
                        className="close-img"
                      />
                    </button>
                  </div>
                  {/* Button 1 */}
                  <button
                    onClick={() => togglePopup(19)}
                    className="icon-btn demo1btn2"
                    style={{ backgroundColor: colors.color11 }}
                  >
                    <PopupColor show={showPopups[19]}>
                      <div>
                        <ChromePicker
                          color={colors.color11}
                          onChangeComplete={(color) =>
                            handleChangeComplete(color, "color11")
                          }
                        />
                      </div>
                    </PopupColor>
                  </button>
                  {/* Button 2 */}
                  <button
                    onClick={() => togglePopup(20)}
                    className="icon-btn demo5btn2"
                  >
                    <img src="/assets/img/demo/lightbulb.png" alt="dimmable" />
                    <Popup show={showPopups[20]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(20)}
                      />
                      <h5>Dimmable</h5>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={sliderValues.value8}
                        className="slider"
                        id="myRange"
                        onChange={(event) =>
                          handleSliderChanges(event, "value8")
                        }
                        style={trackStyles("value8")}
                      />
                      <p>Brightness: {sliderValues.value8}%</p>
                    </Popup>
                  </button>
                  {/* Button 3 */}
                  <button
                    onClick={() => togglePopup(21)}
                    className="icon-btn demo5btn3"
                  >
                    <img src="/assets/img/demo/lightbulb.png" alt="dimmable" />
                    <Popup show={showPopups[21]}>
                      <img
                        src="/assets/img/demo/close.png"
                        alt="close"
                        className="close-container popupsclose"
                        onClick={() => togglePopup(21)}
                      />
                      <h5>Dimmable</h5>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={sliderValues.value9}
                        className="slider"
                        id="myRange"
                        onChange={(event) =>
                          handleSliderChanges(event, "value9")
                        }
                        style={trackStyles("value9")}
                      />
                      <p>Brightness: {sliderValues.value9}%</p>
                    </Popup>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* ====================  Demo Main End ====================*/}
    </>
  );
};

export default DemoMain;
