import React from "react";
import { useTranslation } from "react-i18next";

const MessageMain = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* ==================== Message Main Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <h2 className="mb-4">{t("latestNews.title")}</h2>
            <p>{t("latestNews.desc")}</p>
          </div>
        </div>
      </div>
      {/* ==================== Message Main End ====================*/}
    </>
  );
};

export default MessageMain;
