import React from "react";
import { FaArrowRight } from "react-icons/fa";

const GymPodDetails = () => {
  return (
    <>
      {/* ==================== Gym Pod Details Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_info">
                  <h5 className="widget-title">
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className="widget-info-inner">
                    <h6>Clients</h6>
                    <p>GymPod</p>
                    <h6>Date</h6>
                    <p>2021</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="thumb">
                    <img src="assets/img/Projects/Gympod.png" alt="img" />
                  </div>
                  <div className="details">
                    <h4>What they want?</h4>
                    <p>
                      Gym Pod is a fully private gym where you can book at ease
                      with your smartphone. Helping you achieve a better,
                      healthier and happier lifestyle. Gym Pod would like to
                      have central monitoring and control to remotely switch
                      ON/OFF lighting, air-con and control the door access of
                      the gym.
                    </p>
                    <h4>Our Solution</h4>
                    <p>
                      BLECAC5 lighting controller, BLECLN line controller and
                      C2Me100 door access controller are being deployed to
                      enable controls of LED lights, air-con and EM lock door of
                      the gym. With the C2Me gateway, Gym Pod is also able to
                      perform scheduling for their users and at the same time
                      having “control” of the Pod remotely.
                    </p>
                    <h4>The Results</h4>
                    <p>
                      Administrator is able to ensure lighting and air-con are
                      being switch OFF, remotely, without having to visit the
                      site thereby saving time and electricity. In addition, the
                      administrator can control, schedule and program the
                      brightness levels catered for the different ambient
                      lighting condition in the Pod. This has greatly improved
                      the efficiency and the user experience of their gym goers.
                    </p>
                    <br />
                    <video controls width="640" height="360">
                      <source
                        src="assets/img/Projects/gympod-video.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Gym Pod Details End ====================*/}
    </>
  );
};

export default GymPodDetails;
