import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const ServiceMain = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/*=================== service area start ===================*/}
      <div className="service-area bg-relative pd-top-100">
        <div className="container">
          {/* Consulting Services Section */}
          <div className="section-title">
            <div className="mx-auto mb-4" style={{ maxWidth: "850px" }}>
              <h5 className="mb-3">
                {t("services.consultingService.subtitle")}
              </h5>
              <h2 className="title mb-3">
                <Trans i18nKey="services.consultingService.title">
                  <span>Risk-Free</span> Collaboration, <span>One-Stop</span>{" "}
                  Service
                </Trans>
              </h2>
              <h3 className="mb-3">{t("serviceDetails.consulting.title")}</h3>
              <p className="fs-5 mb-3">
                {t("serviceDetails.consulting.description")}
              </p>
              <div className="row">
                <div className="col">
                  <h5>{t("serviceDetails.faq.title")}</h5>
                  {<Trans i18nKey="serviceDetails.faq.p1"></Trans>}
                </div>
                <div className="col">
                  <h5>{t("serviceDetails.faq.title2")}</h5>
                  <Trans i18nKey="serviceDetails.faq.a1"></Trans>
                </div>
              </div>
            </div>
          </div>

          {/* Custom Development Section */}
          <div className="section-title">
            <div className="mx-auto mb-4" style={{ maxWidth: "850px" }}>
              <h5 className="mb-3">
                {t("services.customDevelopment.subtitle")}
              </h5>
              <h2 className="title mb-3">
                <Trans i18nKey="services.customDevelopment.title">
                  Offering <span>High-Tech</span> Native Development
                </Trans>
              </h2>
              <h3 className="mb-3">{t("serviceDetails.custom.title")}</h3>
              <p className="fs-5 mb-3">
                {t("serviceDetails.custom.description")}
              </p>
              <div className="row">
                <div className="col">
                  <h5>{t("serviceDetails.faq.title")}</h5>
                  <Trans i18nKey="serviceDetails.faq.p2"></Trans>
                </div>
                <div className="col">
                  <h5>{t("serviceDetails.faq.title2")}</h5>
                  <Trans i18nKey="serviceDetails.faq.a2"></Trans>
                </div>
              </div>
            </div>
          </div>

          {/* Operations and Maintenance Section */}
          <div className="section-title">
            <div className="mx-auto" style={{ maxWidth: "850px" }}>
              <h5 className="mb-3">{t("services.operations.subtitle")}</h5>
              <h2 className="title mb-3">
                <Trans i18nKey="services.operations.title">
                  <span>Technical</span> and <span>Operational Security</span>{" "}
                  Maintenance
                </Trans>
              </h2>
              <h3 className="mb-3">{t("serviceDetails.operations.title")}</h3>
              <p className="fs-5 mb-3">
                {t("serviceDetails.operations.description")}
              </p>
              <div className="row">
                <div className="col">
                  <h5>{t("serviceDetails.faq.title")}</h5>
                  <Trans i18nKey="serviceDetails.faq.p3"></Trans>
                </div>
                <div className="col">
                  <h5>{t("serviceDetails.faq.title2")}</h5>
                  <Trans i18nKey="serviceDetails.faq.a3"></Trans>
                </div>
              </div>
            </div>
          </div>

          {/* More Information Section */}
          <div className="section-title">
            <div className="mx-auto" style={{ maxWidth: "850px" }}>
              <h3 className="mb-3">{t("serviceDetails.extraInfo.title")}</h3>
              <p style={{ fontSize: "1.2em" }}>
                {t("serviceDetails.extraInfo.description")}
              </p>
              <div className="row">
                <div className="col-md-6">
                  <ul className="single-list-inner style-check style-check mb-3">
                    <li>
                      <FaCheckCircle /> {t("serviceDetails.extraInfo.1")}
                    </li>
                    <li>
                      <FaCheckCircle /> {t("serviceDetails.extraInfo.2")}
                    </li>
                    <li>
                      <FaCheckCircle /> {t("serviceDetails.extraInfo.3")}
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="single-list-inner style-check style-check mb-3">
                    <li>
                      <FaCheckCircle /> {t("serviceDetails.extraInfo.4")}
                    </li>
                    <li>
                      <FaCheckCircle /> {t("serviceDetails.extraInfo.5")}
                    </li>
                    <li>
                      <FaCheckCircle /> {t("serviceDetails.extraInfo.6")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row justify-content-center me-5">
                <Link
                  to="/products"
                  className="btn btn-primary w-25 mb-3"
                  onClick={scrollToTop}
                >
                  {t("NavBar.products")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceMain;
