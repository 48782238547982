import React from "react";
import DemoMain from "../components/DemoMain";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const Demo = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Demo Main */}
      <DemoMain />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Demo;
