import React, { useState, useEffect, useRef } from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaMapMarkedAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const videoRef = useRef(null);

  const openVideoModal = (videoId) => {
    setSelectedVideo(videoId);
  };

  const closeVideoModal = () => {
    setSelectedVideo(null);
  };

  const handleClickOutside = (event) => {
    if (videoRef.current && !videoRef.current.contains(event.target)) {
      closeVideoModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  // Define the video data
  const videoData = [
    { id: 1, title: "Sentosa Video", src: "assets/vid/Sentosa.mp4" },
    {
      id: 2,
      title: "RP Smart Elderly Care",
      src: "assets/vid/SmartElderlyCare.mp4",
    },
    { id: 3, title: "One2Many", src: "assets/vid/One2Many.mp4" },
    { id: 4, title: "GymPod RGB", src: "assets/vid/gympod-rgbw2.mp4" },
    {
      id: 5,
      title: "Guangzhou Light Exhibition",
      src: "assets/vid/GuangzhouLightExhibition.mp4",
    },
    {
      id: 6,
      title: "Digital Art Innovation Base",
      src: "assets/vid/BlueExcellenceDigitalArtInnovationBase.mp4",
    },
  ];

  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className="footer-area footer-area-2 bg-gray mt-0 pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src="assets/img/C2MeLogo.png" alt="img" />
                </div>
                <div className="details">
                  <p>Send us your email</p>
                  <div className="subscribe mt-4">
                    <input type="text" placeholder="E-mail" />
                    <button>
                      <FaChevronRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Service</h4>
                <ul>
                  <li>
                    <Link to="/service" onClick={scrollToTop}>
                      <FaChevronRight /> Hardware Design
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" onClick={scrollToTop}>
                      <FaChevronRight /> Wireless Connection
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" onClick={scrollToTop}>
                      <FaChevronRight /> Event Collaboration
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" onClick={scrollToTop}>
                      <FaChevronRight /> IoT Projects
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" onClick={scrollToTop}>
                      <FaChevronRight /> Mobile App Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" onClick={scrollToTop}>
                      <FaChevronRight /> Web Development
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Visual Demonstrations</h4>
                <ul>
                  {videoData.map((video) => (
                    <li key={video.id}>
                      <Link onClick={() => openVideoModal(video.src)}>
                        <FaChevronRight /> {video.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Contact us</h4>
                <div className="widget widget_contact">
                  <ul className="details">
                    <li>
                      <FaMapMarkedAlt />
                      180 PAYA LEBAR ROAD #09-03, YI GUANG FACTORY BUILDING,
                      Singapore 409032
                    </li>
                    <li className="mt-3">
                      <FaPhoneAlt /> (+65) 6972 1808
                    </li>
                    <li className="mt-2">
                      <FaEnvelope /> sales@c2me.net
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© Connect2me Pte Ltd</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <a>Trams &amp; Condition</a>
                <a>Privacy Policy</a>
                <Link to={"/contact"} onClick={scrollToTop}>
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Video Modal */}
      {selectedVideo && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Darken background with alpha
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
          onClick={closeVideoModal}
        >
          <div
            style={{ position: "relative" }}
            ref={videoRef}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                color: "white",
                fontSize: "24px",
                cursor: "pointer",
                zIndex: 10000,
              }}
              onClick={closeVideoModal}
            >
              &times;
            </button>
            <video
              style={{ width: "700px", height: "500px" }}
              src={selectedVideo}
              title="Selected Video"
              controls
            ></video>
          </div>
        </div>
      )}

      {/* ================== Footer End ==================*/}
    </>
  );
};

export default Footer;
