import React from "react";
import { useTranslation, Trans } from "react-i18next";

const AboutPartTwo = () => {
  const { t } = useTranslation();

  return (
    <>
      {/*==================== AboutAreaTwo start ====================*/}
      <div
        className="faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110"
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="section-title mb-0 mt-4 mt-lg-0">
          {/* <h6 className="sub-title">{t("aboutUsFaq.subtitle")}</h6> */}
          <div className="container pd-top-90">
            <h2 className="title" style={{ textAlign: "center" }}>
              <Trans i18nKey="aboutUsFaq.title">
                What Does C2ME<span> Stand For</span>
              </Trans>
            </h2>
            <p className="content">{t("aboutUsFaq.description")}</p>
          </div>
        </div>
        <div className="container">
          <div className="row pd-top-30">
            <div
              className="col-xl-6 col-lg-6"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div>
                <div style={{ paddingTop: "32px" }}>
                  <h2 id="headingOne">
                    <a>
                      <span style={{ color: "blue" }}>C</span>2ME
                    </a>
                  </h2>
                  <div>{t("aboutUsFaq.q1")}</div>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <h2 id="headingTwo">
                    <a>
                      C<span style={{ color: "blue" }}>2</span>ME
                    </a>
                  </h2>
                  <div>{t("aboutUsFaq.q2")}</div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-8 order-lg-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner pt-lg-3">
                <div>
                  <div>
                    <h2 id="headingThree">
                      <a>
                        C2<span style={{ color: "blue" }}>M</span>E
                      </a>
                    </h2>
                    <div>{t("aboutUsFaq.q3")}</div>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <h2 id="headingFour">
                      <a>
                        C2M<span style={{ color: "blue" }}>E</span>
                      </a>
                    </h2>
                    <div>{t("aboutUsFaq.q4")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== AboutAreaTwo end ====================*/}
    </>
  );
};

export default AboutPartTwo;
