import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import { useTranslation, Trans } from "react-i18next";

const HomePartOne = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  const openYoutubeLink = () => {
    const youtubeUrl =
      "https://www.youtube.com/watch?v=oObPpwou9z0&t=2s&ab_channel=FrancisLim";
    window.open(youtubeUrl, "_blank");
  };

  return (
    <>
      {/* ================== HomePartOne Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-2 bg-cover"
        style={{ backgroundImage: 'url("./assets/img/bg/8.png")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner pe-xl-5">
                <h6
                  className="subtitle "
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  {t("home.subtitle")}
                </h6>
                <h2
                  className="title "
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  <Trans i18nKey="home.title">
                    {" "}
                    Revolutionize Your <span>Workflow</span> With Connect2Me
                  </Trans>
                </h2>
                <p
                  className="content pe-xl-5 "
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                >
                  <Trans i18nKey="home.description">
                    Connect2Me is a patented hardware cum software technology
                    company that create Breakthrough technologies that allow
                    Machine to Machine and Human to Machine into a single
                    communication without any middleware
                    <br></br>
                    Our wireless communication is secure with <b>Passive RF</b>
                    and with <b>Blockchain Encrypted Rolling Keys</b>
                  </Trans>
                </p>
                <Link
                  className="btn btn-border-base "
                  data-aos="fade-right"
                  data-aos-delay="300"
                  data-aos-duration="1500"
                  to="/about"
                  onClick={scrollToTop}
                >
                  {t("home.btn1")} <FaPlus />
                </Link>
                <div
                  className="d-inline-block align-self-center "
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="1500"
                >
                  <span
                    onClick={openYoutubeLink}
                    className="video-play-btn-hover"
                  >
                    <img src="assets/img/video.svg" alt="img" />{" "}
                    <h6 className="d-inline-block">{t("home.btn2")}</h6>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-9 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="banner-thumb-2 mt-4 mt-lg-0">
                <img
                  className="banner-animate-img banner-animate-img-4"
                  src="assets/img/banner-2/3.png"
                  alt="img"
                />
                <div className="main-img-wrap">
                  <img
                    className="banner-animate-img banner-animate-img-1 left_image_bounce"
                    src="assets/img/banner-2/4.png"
                    alt="img"
                  />
                  <img
                    className="banner-animate-img banner-animate-img-2 left_image_bounce"
                    src="assets/img/banner-2/5.png"
                    alt="img"
                  />
                  <img
                    className="banner-animate-img banner-animate-img-3 top_image_bounce"
                    src="assets/img/banner-2/6.png"
                    alt="img"
                    style={{ borderRadius: "17px" }}
                  />
                  <img
                    className="main-img"
                    src="assets/img/banner-2/1.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== HomePartOne End ==================*/}
    </>
  );
};

export default HomePartOne;
