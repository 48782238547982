import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import MoWDetails from "../components/MoWDetails";

const MOW = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* MoW Details Area */}
      <MoWDetails />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default MOW;
