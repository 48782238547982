import React from "react";
import { useTranslation } from "react-i18next";

const RnDPart1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110"
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="row pd-top-120">
            <div
              className="col-xl-7 col-lg-6"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h6 className="sub-title">{t("RnDPart1.aboutRnD")}</h6>
                <h1 className="title">{t("RnDPart1.RnDCenter")}</h1>
                <p>{t("RnDPart1.content")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Advantages Section */}
      <div className="advantages-section bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>{t("RnDPart1.advantage")}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="advantage-item">
                <h3>{t("RnDPart1.advantage1")}</h3>
                <p>{t("RnDPart1.advantage1Desc")}</p>
              </div>
              <div className="advantage-item">
                <h3>{t("RnDPart1.advantage2")}</h3>
                <p>{t("RnDPart1.advantage2Desc")}</p>
              </div>
              <div className="advantage-item">
                <h3>{t("RnDPart1.advantage3")}</h3>
                <p>{t("RnDPart1.advantage3Desc")}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="advantage-item">
                <h3>{t("RnDPart1.advantage4")}</h3>
                <p>{t("RnDPart1.advantage4Desc")}</p>
              </div>
              <div className="advantage-item">
                <h3>{t("RnDPart1.advantage5")}</h3>
                <p>{t("RnDPart1.advantage5Desc")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RnDPart1;
