import React from "react";
import { FaArrowRight } from "react-icons/fa";

const MoWDetails = () => {
  return (
    <>
      {/* ==================== MoW Details Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_info">
                  <h5 className="widget-title">
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className="widget-info-inner">
                    <h6>Clients</h6>
                    <p>
                      Dewan Tan Sri Mahfoz Khalid Kementerian Kerja Raya (KKR){" "}
                      <br />
                      Ministry of Works, Malaysia
                    </p>
                    <h6>Date</h6>
                    <p>2018</p>
                    <h6>Location</h6>
                    <p>
                      Kuala Lumpur, 50480 Kuala Lumpur, Federal Territory of
                      Kuala Lumpur, Malaysia
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="thumb">
                    <img src="assets/img/Projects/Malaysia.png" alt="img" />
                  </div>
                  <div className="details">
                    <h4>What they want?</h4>
                    <p>
                      Ministry of Works, Malaysia would like to have
                      individually addressible and controllable zone of lighting
                      with no change to existing cabling for the event large
                      hall. <br />
                      <br />
                      The hall is used to host variety of events and the Users
                      wanted each zone to be controllable to create the ambient,
                      mood and scene for the spectrum of events. <br />
                      <br />
                      User wanted all lights to be control via a programmable
                      central console for ease of operation and fast response.
                    </p>
                    <h4>Our Solution</h4>
                    <p>
                      65 nos. of BLECLNS lighting controllers and 6 nos. of
                      BWS4EU switch are deployed to control 65 zones with 6 wall
                      light control switch.
                      <br />
                      <br />
                      Besides wall panels, User can use Apps of mobile devices
                      to control lighting and hall booking.
                    </p>
                    <h4>The Results</h4>
                    <p>
                      Solution provided meet and exceeded Customer’s
                      expectation. It able to control all 65 zones lightings
                      individually. All lightings are zone according to the
                      usage requirement and all zone lighting are controllable
                      by both remote control and mobile app.
                      <br />
                      <br />
                      The solution greatly reduced the need for expensive and
                      extensive cabling works, thereby lowered overall costs of
                      project.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== MoW Details End ====================*/}
    </>
  );
};

export default MoWDetails;
