import React from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const HomePartThree = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  return (
    <>
      {/*=================== HomePartThree Start ===================*/}
      <div className="service-area bg-gray bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6 className="sub-title">{t("home.subtitle3")}</h6>
                <h2 className="title">
                  <Trans i18nKey="home.title3">
                    Optimize Your <span>Performance</span> With Our Products
                  </Trans>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb" style={{ height: "350px" }}>
                  <img src="assets/img/Products/_LW-19.jpg" alt="img" />
                </div>
                <div className="details">
                  <div className="icon mb-3">
                    <img src="assets/img/service/8.png" alt="img" />
                  </div>
                  <Link
                    to="/products"
                    style={{ fontSize: "25px" }}
                    onClick={scrollToTop}
                  >
                    {t("home.product1")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb" style={{ height: "350px" }}>
                  <img
                    src="assets/img/Products/Switches.jpg"
                    alt="img"
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="details">
                  <div className="icon mb-3">
                    <img src="assets/img/service/9.png" alt="img" />
                  </div>
                  <Link
                    to="/products"
                    style={{ fontSize: "25px" }}
                    onClick={scrollToTop}
                  >
                    {t("home.product2")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-2 text-center">
                <div className="thumb" style={{ height: "350px" }}>
                  <img
                    src="assets/img/Products/BWS4EU-S.jpg"
                    alt="img"
                    style={{ height: "230px" }}
                  />
                </div>
                <div className="details">
                  <div className="icon mb-3">
                    <img src="assets/img/service/10.png" alt="img" />
                  </div>
                  <Link
                    to="/products"
                    style={{ fontSize: "25px" }}
                    onClick={scrollToTop}
                  >
                    {t("home.product3")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== HomePartThree End ===================*/}
    </>
  );
};

export default HomePartThree;
