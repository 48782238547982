import React from "react";
import { FaArrowRight } from "react-icons/fa";

const ChurchDetails = () => {
  return (
    <>
      {/* ====================  Church Details Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_info">
                  <h5 className="widget-title">
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className="widget-info-inner">
                    <h6>Clients</h6>
                    <p>Living Sanctuary Brethren Church (LSBC)</p>
                    <h6>Date</h6>
                    <p>2021</p>
                    <h6>Location</h6>
                    <p>2 Hougang Street 22, Singapore</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="thumb">
                    <img src="assets/img/Projects/Church.png" alt="img" />
                  </div>
                  <div className="details">
                    <h4>What they want?</h4>
                    <p>
                      LSBC would like to integrate door access with lighting and
                      aircon controls for both Alpha and Omega wings. There are
                      instances members left venues without turning “OFF”
                      aircons or lights resulting wastage in electricity. Church
                      facility manager and administrator would like to have a
                      system able to provide integrated controls with audit
                      trail. This is to heighten accountability and better use
                      of resources of the church.
                    </p>
                    <h4>Our Solution</h4>
                    <p>
                      39 nos. of C2ME100 door controllers, 21 nos of AC lighting
                      and aircon controllers and 13 nos. of C2ME200/CSG2MBK
                      gateways are deployed to enable the Church to have
                      administrative, remote access and control with full audit
                      trail. USB repeaters are strategically located to ensure
                      good signal coverage for both wings.
                    </p>
                    <h4>The Results</h4>
                    <p>
                      LSBC is able to allocate, schedule and allow booking of
                      rooms. With timer and one-touch shutdown, the system
                      automatically turn “OFF” power outside the booking hours
                      and end of day to save electricity. Administrator can
                      fully track and audit the usage of resources. Members can
                      access doors thru the new Touch-less entry by scanning QR
                      code. This simple, secure and scalable system has greatly
                      improved the efficiency and user experience.
                    </p>
                    <br />
                    <video controls width="640" height="360">
                      <source
                        src="assets/img/Projects/Church.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================  Church Details End ====================*/}
    </>
  );
};

export default ChurchDetails;
