import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  HashRouter,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import About from "./pages/About";
import Tutorial from "./pages/Tutorials";
import TutorialDetails from "./pages/TutorialDetails";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Service from "./pages/Service";
import RnD from "./pages/RnD";
import Products from "./pages/Products";
import Projects from "./pages/Projects";
import Church from "./pages/Church";
import SGDiscoveryCenter from "./pages/SGDiscoveryCenter";
import CoSpace from "./pages/CoSpace";
import AILab from "./pages/AILab";
import Mendaki from "./pages/Mendaki";
import GymPod from "./pages/GymPod";
import MOW from "./pages/MOW";
import Sentosa from "./pages/Sentosa";
import GBTB from "./pages/GBTB";
import Agriculture from "./pages/Agriculture";
import Huawei from "./pages/Huawei";
import Message from "./pages/Message";
import Demo from "./pages/Demo";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Navigate to="/index" />} />
        <Route exact path="/index" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/tutorial" element={<Tutorial />} />
        <Route exact path="/tutorial-details" element={<TutorialDetails />} />
        <Route exact path="/church" element={<Church />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/RnD" element={<RnD />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/discovery-center" element={<SGDiscoveryCenter />} />
        <Route exact path="/co-space" element={<CoSpace />} />
        <Route exact path="/ai-lab" element={<AILab />} />
        <Route exact path="/mendaki" element={<Mendaki />} />
        <Route exact path="/gympod" element={<GymPod />} />
        <Route exact path="/mow" element={<MOW />} />
        <Route exact path="/sentosa" element={<Sentosa />} />
        <Route exact path="/gbtb" element={<GBTB />} />
        <Route exact path="/agriculture" element={<Agriculture />} />
        <Route exact path="/huawei" element={<Huawei />} />
        <Route exact path="/message" element={<Message />} />
        <Route exact path="/demo" element={<Demo />} />
      </Routes>
      <ScrollToTop smooth color="#246BFD" />
    </HashRouter>
  );
}

export default App;
