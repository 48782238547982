import React from "react";
import { FaRegFolderOpen, FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";

const TutorialDetailsMain = () => {
  return (
    <>
      {/* ==================== Tutorials Details Area start ====================*/}
      <div className="blog-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner">
                  <div className="thumb">
                    <img
                      src="assets/img/ParseServer/ParseServer.png"
                      alt="img"
                    />
                  </div>
                  <div className="details">
                    <ul className="blog-meta">
                      <li>
                        <FaRegUser /> By Admin
                      </li>
                      <li>
                        <FaRegFolderOpen /> Category
                      </li>
                    </ul>
                    <h2>Setting up Ubuntu</h2>
                    <h4>Step 1</h4>
                    <p>
                      Open up your command prompt and type in the following to
                      connect to your Ubuntu server:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                ssh
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                root@server-ip-address
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <br />
                    <h4>Step 2</h4>
                    <p>
                      Update the Ubuntu Package Manager so you are up to date
                      with the following command:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                sudo
                              </span>{" "}
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                apt
                              </span>{" "}
                              <span style={{ color: "white" }}>update</span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <br />
                    <h4>Step 3</h4>
                    <p>
                      To check for any updates on software, the command below
                      will upgrade the existing software in Ubuntu:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                sudo
                              </span>{" "}
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                apt
                              </span>{" "}
                              <span style={{ color: "white" }}>upgrade</span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>

                    <br />

                    <h2>Setting up MongoDB on Ubuntu</h2>
                    <p>
                      MongoDB as it is a document-oriented database that is free
                      and open-source and it is scalable, powerful, reliable and
                      easy to use.
                    </p>
                    <h4>Step 1</h4>
                    <p>
                      This downloads the MongoDB GPG key file from the specified
                      URL and adds it to the list of trusted keys on your
                      system, allowing you to verify the authenticity of MongoDB
                      packages before installing them:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                wget
                              </span>{" "}
                              <span
                                class="token function"
                                style={{ color: "#A8CD9F" }}
                              >
                                -qO
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                -
                                https://www.mongodb.org/static/pgp/server-6.0.asc
                                |
                              </span>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                {" "}
                                sudo
                              </span>{" "}
                              <span style={{ color: "white" }}>apt-key </span>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                add -
                              </span>{" "}
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <br />
                    <h4>Step 2</h4>
                    <p>
                      This creates a file in the sources.list.d directory named
                      mongodb-org-4.4.list :
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                echo
                              </span>{" "}
                              <span
                                class="token function"
                                style={{ color: "#A8CD9F" }}
                              >
                                "deb [ arch=amd64,arm64 ]
                                https://repo.mongodb.org/apt/ubuntu
                                $(lsb_release -cs)/mongodb-org/6.0 multiverse"
                              </span>{" "}
                              <span style={{ color: "white" }}>| </span>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                sudo tee
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                /etc/apt/sources.list.d/mongodb-org-6.0.list{" "}
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <br />
                    <h4>Step 3</h4>
                    <p>
                      After running these commands, update your server’s local
                      package index so APT knows where to find the mongodb-org
                      package:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                sudo
                              </span>{" "}
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                apt
                              </span>{" "}
                              <span style={{ color: "white" }}>update</span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <h4>Step 4</h4>
                    <p>
                      Now, you can install MongoDB
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                sudo apt install
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                -y mongodb-org
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <h4>Step 5</h4>
                    <p>
                      To start MongoDB:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                sudo
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                systemctl start mongod.service
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <h4>Step 6</h4>
                    <p>
                      To check whether mongodb is active:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                sudo
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                systemctl status mongod.service
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <h4>Step 7</h4>
                    <p>
                      After confirming that it is running, enable the MongoDB
                      service to start up at boot:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                sudo
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                systemctl enable mongod
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>

                    <br />

                    <h2>Installing Node.js</h2>
                    <h4>Step 1</h4>
                    <p>
                      Use the following command to install node.js v18.x:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                curl
                              </span>{" "}
                              <span
                                class="token function"
                                style={{ color: "#A8CD9F" }}
                              >
                                -fsSL
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                https://deb.nodesource.com/setup_18.x |
                              </span>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                {" "}
                                sudo
                              </span>{" "}
                              <span
                                class="token function"
                                style={{ color: "#A8CD9F" }}
                              >
                                -E bash - && \
                              </span>{" "}
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                {" "}
                                sudo apt-get
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                install -y nodejs{" "}
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <br />
                    <h4>Step 2</h4>
                    <p>
                      Install the yarn package manager:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                npm
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                install -g yarn
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <br />
                    <h4>Step 3</h4>
                    <p>
                      Verify the node version:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                node
                              </span>{" "}
                              <span style={{ color: "white" }}>--version</span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                      <br />
                      <p>
                        Ensure that it is not older than v14.0.0 as the
                        pg-promise library in Yarn does not support NodeJS
                        versions older than v14.0.0.
                      </p>
                    </p>

                    <br />

                    <h2>Setting up Parse Server in Ubuntu</h2>
                    <h4>Step 1</h4>
                    <p>
                      Install the parse-server module using the Yarn package
                      manager:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                yarn
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                global add parse-server
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <br />
                    <h4>Step 2</h4>
                    <p>
                      Create a Parse server configuration file and define the
                      attributes of the parse server:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "65px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span
                                class="token function"
                                style={{ color: "#EFBC9B" }}
                              >
                                nano
                              </span>{" "}
                              <span style={{ color: "white" }}>
                                config.json
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                    </p>
                    <br />
                    <h4>Step 3</h4>
                    <p>
                      Paste the following inside nano config.json:
                      <br />
                      <br />
                      <div
                        className="code-toolbar"
                        style={{
                          backgroundColor: "#070F2B",
                          borderRadius: 10,
                          height: "280px",
                          textAlign: "left",
                          paddingTop: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        <pre class="prefixed command language-bash">
                          <code>
                            <ol>
                              <span style={{ color: "white" }}>
                                {`{
  "appName": "Parse Server Application",
  "databaseURI": "mongodb://localhost:27017/parsedb",
  "appId": "random-app-id",
  "masterKey": "random-master-key",
  "serverURL": "https://localhost:1337/parse",
  "publicServerURL": "https://0.0.0.0:1337/parse",
  "port": 1337,
  “masterKeyIps”: [“your laptop/pc ip address”]
}`}
                              </span>
                            </ol>
                          </code>
                        </pre>
                      </div>
                      <br />
                      <p>
                        Remember to change the serverURL localhost to the ubuntu
                        ip-address.
                      </p>
                      <br />
                      <h4>Step 4</h4>
                      <p>
                        To start the server manually:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  nohup
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  parse-server config.json &
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <h4>Step 5</h4>
                      <p>
                        Run the following command to verify that the Parse
                        server is listening on port 1337 (mentioned in the
                        configuration file):
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  ss -ant
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  | grep 1337
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <br />
                      <h2>Turning Parse Server into a service with systemd</h2>
                      <h4>Step 1</h4>
                      <p>
                        Create a file called{" "}
                        <span style={{ color: "#FF204E" }}>
                          parse.server.service
                        </span>{" "}
                        from{" "}
                        <span style={{ color: "#FF204E" }}>
                          /etc/systemd/system/
                        </span>{" "}
                        :
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  nano
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  /etc/systemd/system/parse.server.service
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <h4>Step 2</h4>
                      <p>
                        Copy and paste the following line into nano
                        parse.server.service:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "380px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span style={{ color: "white" }}>
                                  [Unit] <br />
                                  Description=Parse
                                  <br />
                                  Server service After=mongodb.service
                                  <br />
                                  StartLimitIntervalSec=0
                                  <br />
                                  <br />
                                  [Service]
                                  <br />
                                  Type=simple
                                  <br />
                                  Restart=always
                                  <br />
                                  RestartSec=1
                                  <br />
                                  User=ubuntu
                                  <br />
                                  ExecStart=/usr/local/bin/parse-server
                                  /ubuntu/config.json
                                  <br />
                                  <br />
                                  [Install]
                                  <br />
                                  WantedBy=multi-user.target
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <p>
                        Change the path under ExecStart. Find out where your
                        parse-server and config.json is at. Remember to change
                        your User as well. Save and close the file.
                      </p>
                      <br />
                      <h4>Step 3</h4>
                      <p>
                        Start the parse server:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  systemctl
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  start parse.server.service
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <h4>Step 4</h4>
                      <p>
                        Check the status of the server:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  systemctl
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  status parse.server.service
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <h4>Step 5</h4>
                      <p>
                        This command makes it automatically start on boot:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  systemctl
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  enable parse.server.service
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <br />
                      <h2>Setting up Parse Dashboard on Ubuntu</h2>
                      <p>
                        Parse server comes with a Dashboard for managing your
                        Parse server applications. It is accessible through a
                        web browser.
                      </p>
                      <h4>Step 1</h4>
                      <p>
                        To install parse dashboard:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  yarn
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  global add parse-dashboard
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <h4>Step 2</h4>
                      <p>
                        Create a configuration file for the Parse dashboard:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  nano
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  parse-dashboard-config.json
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <h4>Step 3</h4>
                      <p>
                        Add the following lines. Do not forget to replace your
                        server IP address:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "470px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span style={{ color: "white" }}>
                                  {`{
  "apps": [
    {
      "serverURL": "http://your-server-ip:1337/parse",
      "appId": "SCWASRTWK9Y6AVMP3KFC",
      "masterKey": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
      "allowInsecureHTTP": "true",
      "appName": "ParseApp"
    }
  ],
  "users": [
    {
      "user":"scaler1",
      "pass":"scalewayrocks"
    }
  ],
  "iconsFolder": "icons"
}`}
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <p>
                        You can change your username and password to whatever
                        you want. You should also change your appId as well as
                        masterKey.
                      </p>
                      <br />
                      <h4>Step 4</h4>
                      <p>
                        To manually start the parse server dashboard:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  nohup
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  parse-dashboard --dev --config
                                  parse-darshboard-config.json &
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <h4>Step 5</h4>
                      <p>
                        Run the following command to verify that the Parse
                        server Dashboard is listening on port 4040:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  ss -ant
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  | grep 4040
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <br />
                      <h2>
                        Turning Parse Server dashboard into a service with
                        systemd
                      </h2>
                      <br />
                      <h4>Step 1</h4>
                      <p>
                        Create a file called{" "}
                        <span style={{ color: "#FF204E" }}>
                          parse.server.dashboard.service
                        </span>{" "}
                        from{" "}
                        <span style={{ color: "#FF204E" }}>
                          /etc/systemd/system/
                        </span>{" "}
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  nano
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  parse.server.dashboard.service
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <h4>Step 2</h4>
                      <p>
                        Copy and paste the following line into nano
                        parse.server.service:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "380px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span style={{ color: "white" }}>
                                  [Unit] <br />
                                  Description=Parse Server Dashboard service
                                  <br />
                                  After=parse.server.service
                                  <br />
                                  StartLimitIntervalSec=0
                                  <br />
                                  <br />
                                  [Service]
                                  <br />
                                  Type=simple
                                  <br />
                                  Restart=always
                                  <br />
                                  RestartSec=1
                                  <br />
                                  User=ubuntu
                                  <br />
                                  ExecStart=/usr/local/bin/parse-dashboard --dev
                                  --config /ubuntu/parse-dashboard-config.json
                                  <br />
                                  <br />
                                  [Install]
                                  <br />
                                  WantedBy=multi-user.target
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <p>
                        Remember to change the path under the ExecStart and
                        change the User as well. Save and close the file
                      </p>
                      <br />
                      <h4>Step 3</h4>
                      <p>
                        Start the parse server dashboard:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  systemctl
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  start parse.server.dashboard.service
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <h4>Step 4</h4>
                      <p>
                        Check the status of the server:
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  systemctl
                                </span>{" "}
                                <span style={{ color: "white" }}>
                                  status parse.server.dashboard.service
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                      <br />
                      <br />
                      <h2>Accessing the Parse Server dashboard</h2>
                      <p>
                        Access the Parse server dashboard by visiting the url.
                        Add the credentials that you entered in the
                        parse-darshboard-config.json file.
                        <br />
                        <br />
                        <div
                          className="code-toolbar"
                          style={{
                            backgroundColor: "#070F2B",
                            borderRadius: 10,
                            height: "65px",
                            textAlign: "left",
                            paddingTop: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          <pre class="prefixed command language-bash">
                            <code>
                              <ol>
                                <span
                                  class="token function"
                                  style={{ color: "#EFBC9B" }}
                                >
                                  http
                                </span>
                                <span style={{ color: "white" }}>
                                  ://your-server-ip:4040
                                </span>
                              </ol>
                            </code>
                          </pre>
                        </div>
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="td-sidebar">
                <div className="widget widget-recent-post">
                  <h4 className="widget-title">Links to documentation</h4>
                  <ul>
                    <li>
                      <div className="media">
                        <div className="media-left">
                          <img src="assets/img/widget/1.png" alt="blog" />
                        </div>
                        <div className="media-body align-self-center">
                          <h6 className="title">
                            <Link
                              to="https://ubuntu.com/server/docs"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ubuntu
                            </Link>
                          </h6>
                          <div className="post-info">
                            <span style={{ color: "#0079FF" }}>
                              Find out more about Ubuntu
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div className="media-left">
                          <img src="assets/img/widget/1.png" alt="blog" />
                        </div>
                        <div className="media-body align-self-center">
                          <h6 className="title">
                            <Link
                              to="https://github.com/nodesource/distributions"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              NodeJS
                            </Link>
                          </h6>
                          <div className="post-info">
                            <span style={{ color: "#0079FF" }}>
                              Find out more about NodeJS
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div className="media-left">
                          <img src="assets/img/widget/2.png" alt="blog" />
                        </div>
                        <div className="media-body align-self-center">
                          <h6 className="title">
                            <Link
                              to="https://www.digitalocean.com/community/tutorials/how-to-install-mongodb-on-ubuntu-20-04"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              MongoDB
                            </Link>
                          </h6>
                          <div className="post-info">
                            <span style={{ color: "#0079FF" }}>
                              Find out more about MongoDB
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media">
                        <div className="media-left">
                          <img src="assets/img/widget/3.png" alt="blog" />
                        </div>
                        <div className="media-body align-self-center">
                          <h6 className="title">
                            <Link
                              to="https://www.scaleway.com/en/docs/tutorials/install-parse-server/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Parse Server
                            </Link>
                          </h6>
                          <div className="post-info">
                            <span style={{ color: "#0079FF" }}>
                              Find out more about Parse
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="widget widget_tag_cloud mb-0">
                  <h4 className="widget-title">Tags</h4>
                  <div className="tagcloud">
                    <a href="https://www.mongodb.com/">Node.js</a>
                    <a href="https://www.mongodb.com/">MongoDB</a>
                    <a href="https://ubuntu.com/server">Ubuntu</a>
                    <a href="https://parseplatform.org/">Parse Server</a>
                    <a href="https://parseplatform.org/">
                      Parse Server Dashboard
                    </a>
                    <a href="https://www.mongodb.com/">Database</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Tutorial Details Area End ====================*/}
    </>
  );
};

export default TutorialDetailsMain;
