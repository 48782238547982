import React from "react";
import { FaArrowRight } from "react-icons/fa";

const SentosaDetails = () => {
  return (
    <>
      {/* ==================== Sentosa Details Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_info">
                  <h5 className="widget-title">
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className="widget-info-inner">
                    <h6>Clients</h6>
                    <p>Sentosa</p>
                    <h6>Date</h6>
                    <p>2019</p>
                    <h6>Location</h6>
                    <p>Sentosa, Singapore </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="thumb">
                    <img src="assets/img/Projects/Sentosa.png" alt="img" />
                  </div>
                  <br />
                  <h4>Sentosa Island Lights</h4>
                  <p>
                    Island Lights 2019 transforms into a dazzling rainbow-lit
                    wonderland, showcasing captivating art installations
                    guaranteed to captivate your social media followers. Dive
                    into the interactive exhibits offering something for
                    everyone, and be sure not to miss the nightly Snowflakes
                    Symphony spectacle, uniquely crafted with the innovative
                    <strong>Connect2Me Lights and Technology</strong>. It's an
                    experience not to be missed, where the magic of Connect2Me
                    truly shines.
                  </p>
                  <br />
                  <div className="details">
                    <video controls width="640" height="360">
                      <source
                        src="assets/img/Projects/Sentosa.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Sentosa Details End ====================*/}
    </>
  );
};

export default SentosaDetails;
