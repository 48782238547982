import React from "react";
import { FaArrowRight } from "react-icons/fa";

const AILabDetails = () => {
  return (
    <>
      {/* ==================== AI LAB Details Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_info">
                  <h5 className="widget-title">
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className="widget-info-inner">
                    <h6>Clients</h6>
                    <p>AI Lab</p>
                    <h6>Date</h6>
                    <p>2020</p>
                    <h6>Location</h6>
                    <p>Singapore</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="thumb">
                    <img src="assets/img/Projects/AILab.png" alt="img" />
                  </div>
                  <div className="details">
                    <h4>What they want?</h4>
                    <p>
                      User wanted to have One-button “OFF” at Reception, setup
                      variety of scenes for different function rooms with
                      ability to program and preset settings using wall switch.
                      System needs to have central control able to control
                      entire office without major wiring works
                    </p>
                    <h4>Our Solution</h4>
                    <p>
                      84 nos. of BLECLNS lighting controller, 20 nos of
                      BWS4EU-DC wall switch and 35 nos of BLECDWH wireless
                      controller were deployed to provide remote “ON/OFF” and
                      preset control for the function rooms. 4 nos. of BRUSB10
                      repeater used to provide coverage for entire AI Lab. In
                      addition, User can use mobile app to control all
                      lightings.
                    </p>
                    <h4>The Results</h4>
                    <p>
                      User can control the lights and level using the wall
                      control panel with no wiring. No additional panel is
                      required in the Control Room. Administrator can control
                      and program brightness levels of various sections in the
                      rooms and changes can be made easily via mobile app to
                      cater for different ambient.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== AI LAB Details End ====================*/}
    </>
  );
};

export default AILabDetails;
