import React from "react";
import NavBar from "../components/NavBar";
import AboutPartOne from "../components/AboutPartOne";
import AboutPartTwo from "../components/AboutPartTwo";
import AboutPartThree from "../components/AboutPartThree";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* About Area One */}
      <AboutPartOne />

      {/* About Area Two */}
      <AboutPartTwo />

      {/* About Area Three */}
      {/* <AboutPartThree /> */}

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default About;
