import React from "react";
import { FaArrowRight } from "react-icons/fa";

const MendakiDetails = () => {
  return (
    <>
      {/* ==================== Mendaki Details Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_info">
                  <h5 className="widget-title">
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className="widget-info-inner">
                    <h6>Clients</h6>
                    <p>Mendaki</p>
                    <h6>Date</h6>
                    <p>2019</p>
                    <h6>Location</h6>
                    <p>51 Kee Sun Ave, Singapore 457056 </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="thumb">
                    <img src="assets/img/Projects/Mendaki.png" alt="img" />
                  </div>
                  <div className="details">
                    <h4>What they want?</h4>
                    <p>
                      Mendaki, Council for the Development of Singapore
                      Malay/Muslim Community office @ Woodlands would like to
                      have central control to switch OFF lights of all rooms
                      before leaving office to save electricity.
                    </p>
                    <h4>Our Solution</h4>
                    <p>
                      8 nos. of BLECLNS lighting controller and 5 nos of
                      BWS4EU-DC wall switch were deployed to provide “ON/OFF”
                      and preset control for the function rooms. 1 nos. of
                      BRUSB10 repeater used to provide coverage for entire
                      office. In addition, User can use mobile app to control
                      all lightings . All conventional power on-off wall
                      switches are move to DB room.
                    </p>
                    <h4>The Results</h4>
                    <p>
                      8 nos. of BLECLNS lighting controller and 5 nos of
                      BWS4EU-DC wall switch were deployed to provide “ON/OFF”
                      and preset control for the function rooms. 1 nos. of
                      BRUSB10 repeater used to provide coverage for entire
                      office. In addition, User can use mobile app to control
                      all lightings . All conventional power on-off wall
                      switches are move to DB room.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Mendaki Details End ====================*/}
    </>
  );
};

export default MendakiDetails;
