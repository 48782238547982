import React from "react";
import { useTranslation } from "react-i18next";

const RnDPart2 = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-area pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="animate-img-1 top_image_bounce"
                  src="assets/img/about/2.png"
                  alt="img"
                />
                <img
                  className="animate-img-2 left_image_bounce"
                  src="assets/img/about/3.png"
                  alt="img"
                />
                <img
                  className="animate-img-3 top_image_bounce"
                  src="assets/img/banner/5.svg"
                  alt="img"
                />
                <img
                  className="main-img"
                  src="assets/img/about/SpecialFeature.jpeg"
                  alt="img"
                  style={{ marginTop: "100px" }}
                />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">{t("RnDPart2.keyFeatures")}</h6>
                <h2 className="title">{t("RnDPart2.specialFeatures")}</h2>
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/img/icon/2.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>{t("RnDPart2.function1")}</h5>
                        <p>{t("RnDPart2.function1Desc")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/img/icon/3.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>{t("RnDPart2.function2")}</h5>
                        <p>{t("RnDPart2.function2Desc")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/img/icon/4.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>{t("RnDPart2.function3")}</h5>
                        <p>{t("RnDPart2.function3Desc")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/img/icon/5.svg" alt="img" />
                      </div>
                      <div className="details">
                        <h5>{t("RnDPart2.function4")}</h5>
                        <p>{t("RnDPart2.function4Desc")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RnDPart2;
