import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import SentosaDetails from "../components/SentosaDetails";

const Sentosa = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Case Study Area */}
      <SentosaDetails />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Sentosa;
