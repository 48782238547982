import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import MendakiDetails from "../components/MendakiDetails";

const Mendaki = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Mendaki Details Area */}
      <MendakiDetails />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Mendaki;
