import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import HuaweiDetails from "../components/HuaweiDetails";

const Huawei = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Case Study Area */}
      <HuaweiDetails />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Huawei;
