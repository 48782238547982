import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomePartTwo = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  return (
    <>
      {/* =============== HomePartTwo End ===============*/}
      <div className="about-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mb-4 mb-lg-0 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner">
                <img
                  className="main-img"
                  src="assets/img/about/10.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h6 className="sub-title">{t("home.subtitle2")}</h6>
                <h2 className="title">{t("home.title2")}</h2>
                <p className="content mb-4">{t("home.description2")}</p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> {t("home.check1")}
                      </li>
                      <li>
                        <FaCheckCircle /> {t("home.check2")}
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> {t("home.check3")}
                      </li>
                      <li>
                        <FaCheckCircle /> {t("home.check4")}
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="content">{t("home.description3")}</p>
                <Link
                  className="btn btn-border-base"
                  to="/about"
                  onClick={scrollToTop}
                >
                  {t("home.btn1")} <FaPlus />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== HomePartTwo End ===============*/}
    </>
  );
};

export default HomePartTwo;
