import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import RnDPart1 from "../components/RnDPart1";
import RnDPart2 from "../components/RnDPart2";

const RnD = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      <RnDPart1 />

      <RnDPart2 />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default RnD;
