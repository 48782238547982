import React from "react";
import { FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TutorialMain = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <h2 className="text-center" style={{ padding: "15px" }}>
          Tutorials
        </h2>
      </div>
      <div className="row justify-content-center">
        <div
          className="single-service-inner text-center shadow"
          style={{
            width: "30%",
            margin: "20px", // Adjust margin as needed
          }}
        >
          <div className="thumb">
            <img src="assets/img/ParseServer/ParseServer.png" alt="img" />
          </div>
          <div className="card-body">
            <h5 className="card-title">
              Setting up Ubuntu Database for the First Time
            </h5>
            <p className="card-text">
              Setting up a database in Ubuntu is crucial for efficient data
              management...
            </p>
            <Link
              to="/tutorial-details"
              onClick={scrollToTop}
              className="btn btn-primary d-block mx-auto"
              style={{ maxWidth: "150px" }}
            >
              {t("Read More")} <FaPlus />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialMain;
