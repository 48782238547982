import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import CoSpaceDetails from "../components/CoSpaceDetails";

const CoSpace = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Co-Space Area */}
      <CoSpaceDetails />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default CoSpace;
