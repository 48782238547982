import React from "react";
import TutorialMain from "../components/TutorialMain";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const Tutorial = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Tutorial Main */}
      <TutorialMain />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Tutorial;
