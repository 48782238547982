import React from "react";
import { FaAngleLeft, FaAngleRight, FaPlus } from "react-icons/fa";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const HomePartFour = () => {
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <FaAngleRight className={className} onClick={onClick} />;
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <FaAngleLeft className={className} onClick={onClick} />;
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
  };

  return (
    <>
      {/* =================== HomePartFour Start ===================*/}
      <div
        className="testimonial-area pd-top-120 pd-bottom-90"
        style={{ backgroundImage: 'url("./assets/img/bg/11.png")' }}
      >
        <div className="container">
          <div className="section-title">
            <h6 className="sub-title">Projects</h6>
            <h2 className="title">Our Past Projects</h2>
          </div>
          <div className="slider-control-round">
            <Slider {...settings}>
              <div className="item">
                <div
                  className="single-testimonial-inner text-center"
                  style={{ height: "380px" }}
                >
                  <div style={{ paddingBottom: "20px" }}>
                    <img src="assets/img/Projects/Church.png" alt="img" />
                  </div>
                  <h5>Living Sanctuary Brethren Church</h5>
                </div>
              </div>
              <div className="item">
                <div
                  className="single-testimonial-inner text-center"
                  style={{ height: "380px" }}
                >
                  <div style={{ paddingBottom: "20px" }}>
                    <img src="assets/img/Projects/Co-Space.png" alt="img" />
                  </div>
                  <h5>Co-Space Park</h5>
                </div>
              </div>
              <div className="item">
                <div
                  className="single-testimonial-inner text-center"
                  style={{ height: "380px" }}
                >
                  <div style={{ paddingBottom: "20px" }}>
                    <img src="assets/img/Projects/Sentosa.png" alt="img" />
                  </div>
                  <h5>Sentosa</h5>
                </div>
              </div>
              <div className="item">
                <div
                  className="single-testimonial-inner text-center"
                  style={{ height: "380px" }}
                >
                  <div style={{ paddingBottom: "20px" }}>
                    <img
                      src="assets/img/Projects/GBTB.png"
                      alt="img"
                      style={{ height: "260px" }}
                    />
                  </div>
                  <h5>Gardens By The Bay</h5>
                </div>
              </div>
              <div className="item">
                <div
                  className="single-testimonial-inner text-center"
                  style={{ height: "380px" }}
                >
                  <div style={{ paddingBottom: "20px" }}>
                    <img
                      src="assets/img/Projects/Agriculture.png"
                      alt="img"
                      style={{ height: "260px" }}
                    />
                  </div>
                  <h5>Agriculture</h5>
                </div>
              </div>
            </Slider>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="learnMoreBtn" style={{ paddingTop: "30px" }}>
              <Link
                className="btn btn-border-base"
                to="/projects"
                onClick={scrollToTop}
              >
                Learn More <FaPlus />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* =================== HomePartFour End ===================*/}
    </>
  );
};

export default HomePartFour;
