import React from "react";
import ChurchDetails from "../components/ChurchDetails";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const Church = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Church Details Area */}
      <ChurchDetails />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Church;
