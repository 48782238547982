import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation, Trans } from "react-i18next";

const ContactMain = () => {
  const { t } = useTranslation();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // Please See Documentation for more information
    emailjs
      .sendForm(
        "service_yipk4xg", //YOUR_SERVICE_ID
        "template_71bgc2q", //YOUR_TEMPLATE_ID
        form.current,
        "cwf8kROl5o3__96Ti" //YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            toast.success("Massage Sent Successfully!");
            form.current[0].value = "";
            form.current[1].value = "";
            form.current[2].value = "";
            form.current[3].value = "";
          }
        },
        (error) => {
          if (error.text !== "OK") {
            toast.success("Massage Not Sent!");
          }
        }
      );
  };
  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position="bottom-center" reverseOrder={false} />
      <div className="container">
        <div className="row pd-top-120 justify-content-center">
          <div
            className="col-xl-7 col-lg-6"
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            <div className="section-title mb-0 mt-4 mt-lg-0 text-center">
              <h6 className="sub-title">{t("contactUs.subtitle")}</h6>
              <h2 className="title">
                <Trans i18nKey="contactUs.title">
                  Want To <span>Collaborate</span> With Us? <br />
                </Trans>
              </h2>
              <h5 className="content">{t("contactUs.description")}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="work-process-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            {/* <h6 className="sub-title">CUSTOM DEVELOPMENT</h6> */}
            <h2 className="title">
              <Trans i18nKey="contactUs.title2">
                Just <span>4 Values</span> To Start A Great{" "}
                <span>Collaboration</span>
              </Trans>
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/9.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">01</p>
                  <h5 className="mb-3">{t("contactUs.value1")}</h5>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/10.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">02</p>
                  <h5 className="mb-3">{t("contactUs.value2")}</h5>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/11.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">03</p>
                  <h5 className="mb-3">{t("contactUs.value3")}</h5>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/approval.png  " alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">04</p>
                  <h5 className="mb-3">{t("contactUs.value4")}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="contact-page-inner bg-gray">
            <div className="section-title mb-4 pb-2">
              <h2 className="title">GET IN TOUCH </h2>
              <p className="content mb-0">
                We’re happy to hear from you. Contact us today to learn more
                about our products and how we can work together.
                <p>180 PAYA LEBAR ROAD #09-03</p>
                <p>YI GUANG FACTORY BUILDING</p>
                <p>Singapore 409032</p>
                <p>info@c2me.net</p>
              </p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="name"
                      name="user_name"
                      type="text"
                      placeholder="Enter Your Name."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="email"
                      name="user_email"
                      type="email"
                      placeholder="Enter Your Email."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder="Enter Your Contact Information."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="company"
                      name="company"
                      type="text"
                      placeholder="Enter Your Company and Position"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="single-input-inner">
                    <textarea
                      name="message"
                      id="massage"
                      cols="1"
                      rows="5"
                      placeholder="Enter Your Massage ..."
                      required
                    />
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-base border-radius-5"
                    type="submit"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* contact list */}
      <div className="contact-page-list">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/13.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Contact us</h5>
                  <h6>+65 93375376</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/14.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Our Email</h5>
                  <h6>info@c2me.net</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="media single-contact-list">
                <div className="media-left">
                  <img src="assets/img/icon/15.svg" alt="img" />
                </div>
                <div className="media-body">
                  <h5>Location</h5>
                  <h6>180 PAYA LEBAR ROAD, YI GUANG FACTORY BUILDING</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Map  */}
      <div className="contact-g-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d29208.601361499546!2d90.3598076!3d23.7803374!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1589109092857!5m2!1sen!2sbd" />
      </div>

      {/* ================= Contact Main End =================*/}
    </>
  );
};

export default ContactMain;
