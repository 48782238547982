import React from "react";
import { FaArrowRight } from "react-icons/fa";

const GBTBDetails = () => {
  return (
    <>
      {/* ==================== Gardens By The Bay Start ====================*/}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_info">
                  <h5 className="widget-title">
                    <FaArrowRight /> Project Info
                  </h5>
                  <div className="widget-info-inner">
                    <h6>Clients</h6>
                    <p>Gardens By The Bay</p>
                    <h6>Date</h6>
                    <p>2018</p>
                    <h6>Location</h6>
                    <p>18 Marina Gardens Dr, Singapore 018953</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="thumb">
                    <img src="assets/img/Projects/GBTB.jpg" alt="img" />
                  </div>
                  <div className="details">
                    <video controls width="640" height="360">
                      <source
                        src="assets/img/Projects/GBTB.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Gardens By The Bay Details End ====================*/}
    </>
  );
};

export default GBTBDetails;
