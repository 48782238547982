import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ProjectsMain from "../components/ProjectsMain";

const Projects = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Projects Main */}
      <ProjectsMain />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Projects;
