import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ProductMain from "../components/ProductMain";

const Products = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Product Main */}
      <ProductMain />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Products;
