import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import GBTBDetails from "../components/GBTBDetails";

const GBTB = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Gardens By The Bay Area */}
      <GBTBDetails />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default GBTB;
