import React from "react";

function Popup({ children, show }) {
  const preventClose = (event) => {
    event.stopPropagation();
  };

  return (
    <div className={`popups ${show ? "show" : ""}`}>
      <div className="popuptexts" onClick={preventClose}>
        {children}
      </div>
    </div>
  );
}

export default Popup;
