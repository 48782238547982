import React from "react";
import NavBar from "../components/NavBar";
import HomePartOne from "../components/HomePartOne";
import HomePartTwo from "../components/HomePartTwo";
import HomePartThree from "../components/HomePartThree";
import HomePartFour from "../components/HomePartFour";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      {/* Navigation Bar Two*/}
      <NavBar />

      {/* Banner Two */}
      <HomePartOne />

      {/* About Area Two */}
      <HomePartTwo />

      {/* About Area Two */}
      <HomePartThree />

      {/* Testimonial One */}
      <HomePartFour />

      {/* Footer Two */}
      <Footer />
    </>
  );
};

export default Home;
