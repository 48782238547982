import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import AILabDetails from "../components/AILabDetails";

const AILab = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* AI Lab Area */}
      <AILabDetails />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default AILab;
