import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import DiscoveryDetails from "../components/DiscoveryDetails";

const SGDiscoveryCenter = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Singapore Discovery Center Details Area */}
      <DiscoveryDetails />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default SGDiscoveryCenter;
