import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductMain = () => {
  const openBRUSB10InNewTab = () => {
    // Change 'your-pdf-file.pdf' to the path of your PDF file
    const pdfUrl = "/assets/pdf/BRUSB10.pdf";
    window.open(pdfUrl, "_blank");
  };

  const openBLECAC5InNewTab = () => {
    // Change 'your-pdf-file.pdf' to the path of your PDF file
    const pdfUrl = "/assets/pdf/BLECAC5.pdf";
    window.open(pdfUrl, "_blank");
  };

  const openBRC4InNewTab = () => {
    // Change 'your-pdf-file.pdf' to the path of your PDF file
    const pdfUrl = "/assets/pdf/BRC4.pdf";
    window.open(pdfUrl, "_blank");
  };

  const openBWS4EUSInNewTab = () => {
    // Change 'your-pdf-file.pdf' to the path of your PDF file
    const pdfUrl = "/assets/pdf/BWS4EU-S.pdf";
    window.open(pdfUrl, "_blank");
  };

  const openBLECLOInNewTab = () => {
    // Change 'your-pdf-file.pdf' to the path of your PDF file
    const pdfUrl = "/assets/pdf/BLELO.pdf";
    window.open(pdfUrl, "_blank");
  };

  const openBLECTW3InNewTab = () => {
    // Change 'your-pdf-file.pdf' to the path of your PDF file
    const pdfUrl = "/assets/pdf/BLECTW3.pdf";
    window.open(pdfUrl, "_blank");
  };

  const openBLECLNMWHInNewTab = () => {
    // Change 'your-pdf-file.pdf' to the path of your PDF file
    const pdfUrl = "/assets/pdf/BLECLNS.pdf";
    window.open(pdfUrl, "_blank");
  };

  const { t } = useTranslation();

  return (
    <>
      {/* Product Main Start */}
      <div
        className="service-area bg-relative pd-bottom-90"
        style={{ backgroundColor: "#EEEEEE" }}
      >
        <div className="container">
          <div className="section-title text-center ">
            <h6 className="sub-title" style={{ marginTop: "25px" }}>
              {t("products.subtitle")}
            </h6>
            <h2 className="title">{t("products.title")}</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{
                  height: "400px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Products/C2MEModule.png"
                    alt="img"
                    style={{ width: "220px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>C2ME MODULE</Link>
                  </h5>
                  <div className="learnMoreBtn" style={{ paddingTop: "10px" }}>
                    <a className="btn btn-border-base">
                      Learn More <FaPlus />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{
                  height: "400px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Products/BLECLOWH.jpg"
                    alt="img"
                    style={{ width: "170px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>BLECLO 5-24V BLE Controller</Link>
                  </h5>
                  <div className="learnMoreBtn" style={{ paddingTop: "10px" }}>
                    <a
                      className="btn btn-border-base"
                      onClick={openBLECLOInNewTab}
                    >
                      Learn More <FaPlus />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{
                  height: "400px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Products/BLECLNMWH.jpg"
                    alt="img"
                    style={{ width: "140px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>BLECLNS 0-10V Line Voltage BLE Controller</Link>
                  </h5>
                  <div className="learnMoreBtn" style={{ paddingTop: "10px" }}>
                    <a
                      className="btn btn-border-base"
                      onClick={openBLECLNMWHInNewTab}
                    >
                      Learn More <FaPlus />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{
                  height: "400px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Products/BRUSB-1.jpg"
                    alt="img"
                    style={{ width: "170px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>BRUSB-1 Repeater</Link>
                  </h5>
                  <div className="learnMoreBtn" style={{ paddingTop: "10px" }}>
                    <a
                      className="btn btn-border-base"
                      onClick={openBRUSB10InNewTab}
                    >
                      Learn More <FaPlus />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{
                  height: "400px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Products/BLECAC5WH.jpg"
                    alt="img"
                    style={{ width: "170px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>BLECAC5WH RGBW BLE Controller</Link>
                  </h5>
                  <div className="learnMoreBtn" style={{ paddingTop: "10px" }}>
                    <a
                      className="btn btn-border-base"
                      onClick={openBLECAC5InNewTab}
                    >
                      Learn More <FaPlus />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{
                  height: "400px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Products/BRC4.jpg"
                    alt="img"
                    style={{ width: "170px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>BRC4 Remote Controller</Link>
                  </h5>
                  <div className="learnMoreBtn" style={{ paddingTop: "10px" }}>
                    <a
                      className="btn btn-border-base"
                      onClick={openBRC4InNewTab}
                    >
                      Learn More <FaPlus />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{
                  height: "400px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Products/BWS4EU-S.jpg"
                    alt="img"
                    style={{ width: "140px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>BWS4EU-S Single Wire EU Wall Switch(0dB)</Link>
                  </h5>
                  <div className="learnMoreBtn" style={{ paddingTop: "10px" }}>
                    <a
                      className="btn btn-border-base"
                      onClick={openBWS4EUSInNewTab}
                    >
                      Learn More <FaPlus />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{
                  height: "400px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Products/BLECTW3WH.jpg"
                    alt="img"
                    style={{ width: "140px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>BLECTW3 Tunable White BLE Controller</Link>
                  </h5>
                  <div className="learnMoreBtn" style={{ paddingTop: "10px" }}>
                    <a
                      className="btn btn-border-base"
                      onClick={openBLECTW3InNewTab}
                    >
                      Learn More <FaPlus />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-service-inner text-center"
                style={{
                  height: "400px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="thumb">
                  <img
                    src="assets/img/Products/C2MeRGBCube.png"
                    alt="img"
                    style={{ width: "190px" }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link>C2ME RGB CUBE</Link>
                  </h5>
                  <div className="learnMoreBtn" style={{ paddingTop: "10px" }}>
                    <a className="btn btn-border-base">
                      Learn More <FaPlus />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================= Product Main End =================*/}
    </>
  );
};

export default ProductMain;
