import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ServiceMain from "../components/ServiceMain";

const Service = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Service Area One */}
      <ServiceMain />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Service;
